import React, { Component } from "react";

class Landing extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="landing">
        <div className="header">
          {/*<h4>Bienvenu</h4>*/}
          <img src="https://allfredo.s3.ca-central-1.amazonaws.com/src/Allr.png" />
          <h5>We are cooking a success here</h5>
        </div>

        {/*<h3>Soon on your favorite screen</h3>*/}

        {/*<ul className="feature-list">
          <li>
            <h3>Réservation</h3>
          </li>
          <li>
            <h3>Take-out</h3>
          </li>
          <li>
            <h3>Cantine</h3>
          </li>
          <li>
            <h3>Répertoire</h3>
          </li>
        </ul>*/}

        {/*<div className="axyo-app">
          <img src="https://allfredo.s3.ca-central-1.amazonaws.com/src/Group+4885.png" />
        </div>*/}
      </div>
    );
  }
}

export default Landing;
