import axios from "axios";

export function setTokenHeader(token) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export function apiCall(method, path, data) {
  return new Promise((resolve, reject) => {
    return axios[method](path, data)
      .then(res => {
        if (res.status === 440) {
          console.log("new Token Please");
        }
        // ensureTokenValidation();
        // console.log(res);

        return resolve(res.data);
      })
      .catch(err => {
        return reject(err.response.data.error);
      });
  });
}

export function withOptionsApiCall(method, path, data, options) {
  return new Promise((resolve, reject) => {
    return axios[method](path, data, options)
      .then(res => {
        return resolve(res.data);
      })
      .catch(err => {
        return reject(err.response);
      });
  });
}
