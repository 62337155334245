import React, { Component } from "react";
import "../App.css";
import { BrowserRouter as Router } from "react-router-dom";

import Navbar from "./Navbar";
import Main from "./Main";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      currentUser: {}
    };
  }

  registrerUser = user => {
    this.setState({
      currentUser: user
    });
  };

  render() {
    return (
      <Router>
        <div className="App">
          {/*<Navbar authenticated={this.state.authenticated} currentUser={this.state.currentUser} />*/}
          <Main
            authenticated={this.state.authenticated}
            currentUser={this.state.currentUser}
            registrerUser={this.registrerUser}
          />
        </div>
      </Router>
    );
  }
}

library.add(fas, far, fab);

export default App;
