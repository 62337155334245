import React, { Component } from "react";

import { allfredoSignIn } from "../../apiCall/auth";

class CustomSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restoName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      phone: "",
      website: "",
      location: {},
      restoType: "",
      priceRange: "",
      offeredLanguage: ["fr"],
      preferedLanguage: "fr",
      en_desc: "",
      fr_desc: "",
      es_desc: "",
      businessHours: {},
      personsOfContact: [], // NOTE: name / title / phoneNumber / email
      pocName: "",
      pocPhone: "",
      pocEmail: "",
      pocTitle: "",
      location: {
        geoLocation: {
          coordinates: [-71.4534, 87.53242]
        },
        civicLocation: {
          label: "asdf fasfdsf a"
        }
      }
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  selectLanguage = lng => {
    this.setState({
      preferedLanguage: lng
    });
  };

  toggleLanguage = lng => {
    let ind = this.state.offeredLanguage.indexOf(lng);
    let updatedList = [...this.state.offeredLanguage];
    if (ind === -1) {
      updatedList.push(lng);
    } else {
      updatedList.splice(ind, 1);
    }
    this.setState({
      offeredLanguage: updatedList
    });
  };

  submit = async () => {
    try {
      // NOTE: LOCATION / BUSINESS HOURS

      let { fr_desc, en_desc, es_desc, pocName, pocPhone, pocEmail, pocTitle, ...filteredState } = this.state;

      let data = {
        description: {
          fr: fr_desc,
          en: en_desc,
          es: es_desc
        },
        personsOfContact: {
          name: pocName,
          phoneNumber: pocPhone,
          email: pocEmail,
          title: pocTitle
        },
        ...filteredState
      };

      let user = await allfredoSignIn(data);

      this.props.registrerUser(user);
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <div className="custom-sign">
        <h3 className="title">Créons un compte Allfredo</h3>

        <form className="main-form" onSubmit={e => e.preventDefault()}>
          <div className="input-section">
            <h5 className="input-title">Nom de votre restaurant</h5>
            <input name="restoName" onChange={this.handleChange} value={this.state.restoName} min={3} max={100} />
          </div>

          <div className="input-section">
            <h5 className="input-title">Adresse électronique</h5>
            <input name="email" type="email" onChange={this.handleChange} value={this.state.email} min={3} max={200} />
          </div>

          <div className="input-section">
            <h5 className="input-title">Mot de passe</h5>
            <input
              name="password"
              type="password"
              onChange={this.handleChange}
              value={this.state.password}
              min={3}
              max={100}
            />
          </div>

          <div className="input-section">
            <h5 className="input-title">Confirmation mot de passe</h5>

            <input
              name="passwordConfirmation"
              type="password"
              onChange={this.handleChange}
              value={this.state.passwordConfirmation}
              min={3}
              max={100}
            />
          </div>

          <div className="input-section">
            <h5 className="input-title">Téléphone</h5>
            <input name="phone" onChange={this.handleChange} value={this.state.phone} min={3} max={200} />
          </div>

          <div className="input-section">
            <h5 className="input-title">Site web</h5>
            <input
              name="website"
              placeholder="optionnel"
              onChange={this.handleChange}
              value={this.state.website}
              min={3}
              max={200}
            />
          </div>

          <div className="input-section">
            <h5 className="input-title">Type de cuisine</h5>
            <select name="restoType" onChange={this.handleChange}>
              <option value="asian">Cuisine Asiatique</option>
              <option value="italian">Cuisine Italienne</option>
              <option value="french">Cuisine Française</option>
              <option value="tradionnal">Cuisine Traditionennel</option>
              <option value="ítalian">Cuisine Indienne</option>
              <option value="indie">Cuisine Indienne</option>
            </select>
          </div>

          <div className="input-section">
            <h5 className="input-title">Prix moyen par personne (sans alcool)</h5>
            <select name="priceRange" onChange={this.handleChange}>
              <option value="$">- de 15$</option>
              <option value="$$">15 à 25$</option>
              <option value="$$$">25 à 50$</option>
              <option value="$$$$">+ de 50$</option>
            </select>
          </div>

          <div className="lng-selection">
            <h5 className="input-title">Langues d'affichage</h5>
            <ul>
              <li
                className={`${this.state.offeredLanguage.indexOf("fr") !== -1 ? "selected" : ""}`}
                onClick={() => this.toggleLanguage("fr")}>
                <h5>Francais</h5>
              </li>
              <li
                className={`${this.state.offeredLanguage.indexOf("en") !== -1 ? "selected" : ""}`}
                onClick={() => this.toggleLanguage("en")}>
                <h5>English</h5>
              </li>
              <li
                className={`${this.state.offeredLanguage.indexOf("es") !== -1 ? "selected" : ""}`}
                onClick={() => this.toggleLanguage("es")}>
                <h5>Espanol</h5>
              </li>
            </ul>
          </div>

          <div className="lng-selection">
            <h5 className="input-title">Langue par défault </h5>
            <ul>
              <li
                className={`${this.state.preferedLanguage.indexOf("fr") !== -1 ? "selected" : ""}`}
                onClick={() => this.selectLanguage("fr")}>
                <h5>Francais</h5>
              </li>
              <li
                className={`${this.state.preferedLanguage.indexOf("en") !== -1 ? "selected" : ""}`}
                onClick={() => this.selectLanguage("en")}>
                <h5>English</h5>
              </li>
              <li
                className={`${this.state.preferedLanguage.indexOf("es") !== -1 ? "selected" : ""}`}
                onClick={() => this.selectLanguage("es")}>
                <h5>Espanol</h5>
              </li>
            </ul>
          </div>

          <div className="desc-section">
            <h5 className="input-title">Description</h5>
            <ul>
              {this.state.offeredLanguage.indexOf("fr") !== -1 ? (
                <li className={`${this.state.offeredLanguage.indexOf("fr") !== -1 ? "selected" : ""}`}>
                  <h5 className="sec-input-title">Fr</h5>
                  <textarea
                    placeholder="description"
                    name="fr_desc"
                    value={this.state.fr_desc}
                    onChange={this.handleChange}
                  />
                </li>
              ) : null}
              {this.state.offeredLanguage.indexOf("en") !== -1 ? (
                <li className={`${this.state.offeredLanguage.indexOf("en") !== -1 ? "selected" : ""}`}>
                  <h5 className="sec-input-title">En</h5>
                  <textarea
                    placeholder="description"
                    name="en_desc"
                    value={this.state.en_desc}
                    onChange={this.handleChange}
                  />
                </li>
              ) : null}
              {this.state.offeredLanguage.indexOf("es") !== -1 ? (
                <li className={`${this.state.offeredLanguage.indexOf("es") !== -1 ? "selected" : ""}`}>
                  <h5 className="sec-input-title">Es</h5>
                  <textarea
                    placeholder="description"
                    name="es_desc"
                    value={this.state.es_desc}
                    onChange={this.handleChange}
                  />
                </li>
              ) : null}
            </ul>
          </div>

          <div className="poc-form">
            <h5 className="input-title">Personne de contact</h5>
            <div className="poc-section">
              <h5 className="sec-input-title">Nom</h5>
              <input
                name="pocName"
                placeholder="Nom"
                onChange={this.handleChange}
                value={this.state.pocName}
                min={3}
                max={200}
              />
            </div>
            <div className="poc-section">
              <h5 className="sec-input-title">Phone</h5>
              <input
                name="pocPhone"
                placeholder="Phone"
                onChange={this.handleChange}
                value={this.state.pocPhone}
                min={3}
                max={200}
              />
            </div>
            <div className="poc-section">
              <h5 className="sec-input-title">Email</h5>
              <input
                name="pocEmail"
                placeholder="Email"
                onChange={this.handleChange}
                value={this.state.pocEmail}
                min={3}
                max={200}
              />
            </div>
            <div className="poc-section">
              <h5 className="sec-input-title">Title</h5>
              <input
                name="pocTitle"
                placeholder="Title"
                onChange={this.handleChange}
                value={this.state.pocTitle}
                min={3}
                max={200}
              />
            </div>
          </div>

          {/*// TODO: businessHours / location*/}
        </form>
        <button onClick={this.submit}>Creer</button>
      </div>
    );
  }
}

export default CustomSignUp;
