import { apiCall } from "../services/api";

export const allfredoSignIn = async info => {
  try {
    let user = await apiCall("post", `/api/auth/sign/al`, info);
    return user;
  } catch (e) {
    throw e;
  }
};
