import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AxyoSignUp from "./AxyoSignUp";
import CustomSignUp from "./CustomSignUp";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signDisplay: "root"
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    let display;
    switch (this.state.signDisplay) {
      case "root":
        display = (
          <div className="signup-options">
            <h3 className="title">Crée ton compte</h3>

            <FontAwesomeIcon className="back-icon" icon="plug" />
            <div className="option" onClick={() => this.setState({ signDisplay: "axyo" })}>
              <h3 className="sec-title">AXYO</h3>
              <p>Sign in and connect via your Axyo account</p>
              <div className="axyo-btn">
                <button>
                  <h3 className="logo-axyo">!</h3>
                  via compte AXYO
                </button>
              </div>
            </div>
            <div className="option" onClick={() => this.setState({ signDisplay: "custom" })}>
              <h3 className="sec-title">Compte Allfredo</h3>
              <p>Créez seulement un compte Allfredo pour l'instant</p>
              <button>Allfredo seulement</button>
            </div>
          </div>
        );
        break;

      case "axyo":
        display = <AxyoSignUp />;
        break;

      case "custom":
        display = <CustomSignUp />;
        break;

      default:
    }
    return <div className="signup">{display}</div>;
  }
}

export default SignUp;
