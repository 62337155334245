import React, { Component } from "react";
import i18n from "i18next";
import { Link, withRouter } from "react-router-dom";
// import { withNamespaces } from "react-i18next";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="navbar">
        <img
          className="nav-logo"
          src="https://allfredo.s3.ca-central-1.amazonaws.com/src/logo.png"
        />
        {/*<div className="nav-options">
          <Link to="/signin">LogIn</Link>
          <Link to="/signup">SignUp</Link>
        </div>*/}
      </div>
    );
  }
}

export default withRouter(Navbar);
