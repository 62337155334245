import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import * as moment from "moment";
// import "moment/locale/es";
// import "moment/locale/fr";

import Landing from "./routes/Landing";
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { authenticated, currentUser, registrerUser } = this.props;

    return (
      <div className="main">
        <Switch>
          <Route exact path="/">
            {/*<Route exact path="/" render={props => <Landing />} >*/}
            {authenticated === true ? <Redirect to="/home" /> : <Landing />}
          </Route>
          <Route exact path="/signin" render={props => <SignIn registrerUser={registrerUser} />} />
          <Route exact path="/signup" render={props => <SignUp registrerUser={registrerUser} />} />
          {/*<Route exact path="/home" render={props => <Home />} />
          <Route exact path="/map" render={props => <Map />} />*/}
        </Switch>
      </div>
    );
  }
}

export default withRouter(Main);
