import React, { Component } from "react";

class AxyoSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  submit = () => {
    try {
      // NOTE: sign in
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <div className="axyo-sign">
        <h3 className="sec-title">Connection via AXYO</h3>
        <form className="" onSubmit={e => e.preventDefault()}>
          <div className="line">
            <h5>Username</h5>
            <input name="username" value={this.state.username} onChange={this.handleChange} />
          </div>
          <div className="line">
            <h5>Password</h5>
            <input name="password" value={this.state.password} onChange={this.handleChange} />
          </div>
          <button onClick={this.submit}>Log</button>
        </form>
      </div>
    );
  }
}

export default AxyoSignUp;
