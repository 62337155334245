import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import Backend from 'i18next-locize-backend';
import options from "./backendOptions.json";
import { resources } from './translation';


i18n
  // .use(Backend) // passes i18n down to react-i18next
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    // load: 'languageOnly',
    ns: ['Common', 'Nav', 'Auth', 'Profile', 'Routes', 'UserMenu', 'Homepage', 'Notif'], // ns on locize
    // defaultNS: 'Nav',
    // debug: true,
    // saveMissing: true,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react : {
        wait   : true
    },
    backend: options
  });

export default i18n;
