import Auth from './en/Auth.json';
import AuthFr from './fr/Auth.json';
import AuthEs from './es/Auth.json';

import Common from './en/Common.json';
import CommonFr from './fr/Common.json';
import CommonEs from './es/Common.json';

import Homepage from './en/Homepage.json';
import HomepageFr from './fr/Homepage.json';
import HomepageEs from './es/Homepage.json';

import Nav from './en/Nav.json';
import NavFr from './fr/Nav.json';
import NavEs from './es/Nav.json';

import Routes from './en/Routes.json';
import RoutesFr from './fr/Routes.json';
import RoutesEs from './es/Routes.json';

import Profile from './en/Profile.json';
import ProfileFr from './fr/Profile.json';
import ProfileEs from './es/Profile.json';

import Notif from './en/Notif.json';
import NotifFr from './fr/Notif.json';
import NotifEs from './es/Notif.json';

import UserMenu from './en/UserMenu.json';
import UserMenuFr from './fr/UserMenu.json';
import UserMenuEs from './es/UserMenu.json';


export const resources = {
  en: {
    Auth: Auth,
    Common: Common,
    Homepage: Homepage,
    Nav: Nav,
    Notif: Notif,
    Profile: Profile,
    Routes: Routes,
    UserMenu: UserMenu
  },
  fr: {
    Auth: AuthFr,
    Common: CommonFr,
    Homepage: HomepageFr,
    Nav: NavFr,
    Notif: NotifFr,
    Profile: ProfileFr,
    Routes: RoutesFr,
    UserMenu: UserMenuFr
  },
  es: {
    Auth: AuthEs,
    Common: CommonEs,
    Homepage: HomepageEs,
    Nav: NavEs,
    Notif: NotifEs,
    Profile: ProfileEs,
    Routes: RoutesEs,
    UserMenu: UserMenuEs
  }
};
